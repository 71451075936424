export const MULTIPLE_WELCOME_LANGUAGES = {
  en: {
    text:
      "Hello, I am here to assist you with any medical issues you are facing, do not hesitate to talk to me!",
  },
  "zh-TW": {
    text: "您好，我將協助您解決任何醫療問題，請隨時與我交談！",
  },
  fr: {
    text:
      "Bonjour, je suis là pour vous aider avec tout problème médical que vous rencontrez, n'hésitez pas à me parler!",
  },
  ja: {
    text:
      "こんにちは、私はあなたが直面している医療問題についてお手伝いします、私に話すのをためらわないでください！",
  },
};
export const CONVERT_LANGUAGES = {
  en: "English",
  "zh-TW": "Chinese",
  fr: "French",
  ja: "Japanese",
};
