<template>
  <div class="container-fluid">
    <div class="my-3 d-flex justify-content-end align-items-center gap-4">
      <div class="fs-6">
        Language: <strong>{{ getLang() }}</strong>
      </div>
      <div class="btn btn-outline-primary" @click="logout">Logout</div>
    </div>
    <div class="row">
      <!-- Left Session: clientAvatar Details at the Top -->

      <div class="col-3 bg-light d-flex flex-column align-items-center py-3">
        <div
          style="max-width: 100%; min-with: 250px"
          class="mb-5 position-relative video-avatar"
        >
          <DrAvatar v-if="mounted" :taskInput="drAvatarLastMessage" />
        </div>
        <p class="d-flex align-items-center gap-2">
          <strong>AI AVATAR</strong>
          <span class="status-indicator bg-success rounded-circle ml-2"></span>
        </p>
      </div>

      <!-- Center Session: Conversation -->
      <div class="col-6 d-flex flex-column p-1 bg-secondary">
        <div
          class="conversation-window flex-grow-1 p-3 bg-white rounded overflow-auto"
          ref="chatBox"
        >
          <div v-if="!messages.length" class="text-center fw-bold">
            Empty box :)
          </div>
          <div
            v-for="(message, index) in messages"
            :key="index"
            class="mb-3 d-flex"
            :class="{
              'justify-content-end': message.sender === 'clientAvatar',
              'justify-content-start': message.sender === 'drAvatar',
            }"
          >
            <div
              class="message-content p-2 rounded"
              :class="{
                'bg-primary text-white': message.sender === 'clientAvatar',
                'bg-light': message.sender === 'drAvatar',
              }"
            >
              <p class="mb-0" v-html="getTextRaw(message.text)">
              </p>
              <small
                :class="{
                  'text-white': message.sender === 'clientAvatar',
                  'text-black': message.sender === 'drAvatar',
                }"
                class="d-block fs-7 mt-1 text-end"
                ><i>{{ convertTimeFunc(message.sendAt) }}</i></small
              >
            </div>
          </div>
          <div
            class="message-content p-2 rounded bg-light"
            v-if="loadingDrAvatar"
          >
            <!-- Loading response -->
            <div class="lds-ellipsis mb-0">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      </div>

      <!-- Right Session: drAvatar Details at the Top + Message Input -->
      <div class="col-3 bg-light d-flex flex-column align-items-center py-3">
        <img
          class="rounded-circle mb-5 client-avatar w-100"
          src="https://www.svgrepo.com/show/382100/female-avatar-girl-face-woman-user-7.svg"
          alt="drAvatar Avatar"
        />
        <div class="message-voice">
          <ButtonVoice v-if="false" />
        </div>
        <p class="d-flex align-items-center gap-2">
          <strong>Client</strong>
          <span class="status-indicator bg-success rounded-circle ml-2"></span>
        </p>
        <div class="message-input mt-3 w-100">
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              placeholder="Type a message..."
              v-model="newMessage"
              @keyup.enter="sendMessage"
            />
            <div class="input-group-append">
              <button class="btn btn-primary" @click="sendMessage">Send</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DrAvatar from "@/components/Livestream/Avatar.vue";
import ButtonVoice from "@/components/Common/ButtonVoice.vue";
import { MULTIPLE_WELCOME_LANGUAGES, CONVERT_LANGUAGES } from "@/lang";
import { convertTime } from "@/utils";
export default {
  name: "dashboard",
  components: {
    DrAvatar,
    ButtonVoice,
  },
  data() {
    return {
      newMessage: "",
      messages: [
        { sender: "drAvatar", text: ""},
      ],
      loadingDrAvatar: false,
      drAvatarLastMessage: "",
      mounted: false,
      lang: localStorage.getItem("lang"),
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.mounted = true;
    });
    this.messages[0].text = MULTIPLE_WELCOME_LANGUAGES[this.lang]?.text;
  },
  methods: {
    convertTimeFunc(time) {
      return convertTime(time);
    },
    getLang() {
      return CONVERT_LANGUAGES[this.lang];
    },
    logout() {
      this.$store.dispatch("auth/logout");
      this.$router.push({ name: "index" });
    },
    sendMessage() {
      if (this.newMessage.trim()) {
        this.messages.push({
          sender: "clientAvatar", // For now, always clientAvatar. You can toggle between users.
          text: this.newMessage,
          sendAt: new Date().toISOString(),
        });

        this.newMessage = "";
        this.consultMessage();
        setTimeout(() => {
          this.scrollToBottomChatWindow();
        }, 100);
      }
    },
    scrollToBottomChatWindow() {
      const chatBox = this.$refs.chatBox;
      chatBox.scrollTop = chatBox.scrollHeight;
    },
    getTextRaw(text) {
      return text.replace(/(?:\r\n|\r|\n)/g, "<br>")
    },
    consultMessage() {
      this.loadingDrAvatar = true;
      const chatFlag = this.$store.state.expertmind.lastChatFlag;
      const sliceChats = this.messages.slice(1, this.messages.length - 1);
      const lChatQ = sliceChats.filter((msg) => msg.sender === "clientAvatar");
      const lChatA = sliceChats.filter((msg) => msg.sender === "drAvatar");
      const pastChat = lChatQ.map((msg, index) => {
        return {
          chatQ: msg.text,
          chatA: lChatA[index].text,
        };
      });
      const payload = {
        userCDR: this.messages[this.messages.length - 1].text,
        chatFlag: chatFlag,
        pastChat: pastChat?.length ? pastChat : null,
        lang: localStorage.getItem("lang"),
        token: localStorage.getItem("token"),
      };
      this.$store
        .dispatch("expertmind/consult", payload)
        .then((response) => {
          this.drAvatarLastMessage = response.token.drAICDR;
          this.messages.push({
            sender: "drAvatar",
            text: response.token.drAICDR,
            sendAt: new Date().toISOString(),
          });
          this.loadingDrAvatar = false;
        })
        .catch(() => {
          this.loadingDrAvatar = false;
        });
    },
  },
};
</script>

<style scoped>
.fs-7 {
  font-size: 0.675rem;
}
.status-indicator {
  width: 10px;
  height: 10px;
}

.conversation-window {
  height: calc(100vh - 170px);
}

.message-content {
  max-width: 60%;
}
.client-avatar,
.video-avatar {
  border-radius: 50%; /* Circular border */
}

.lds-ellipsis,
.lds-ellipsis div {
  box-sizing: border-box;
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33.33333px;
  width: 13.33333px;
  height: 13.33333px;
  border-radius: 50%;
  background: currentColor;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
</style>
