<template>
  <div>
    <div
      v-if="!connected"
      class="d-flex align-items-center justify-content-center"
    >
      <div class="lds-ripple">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <video id="mediaElement" ref="mediaElement" autoplay></video>
  </div>
</template>

<script>
export default {
  props: {
    taskInput: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      avatarID: "Tyler-insuit-20220721",
      sessionInfo: {},
      peerConnection: null,
      connected: false,
    };
  },
  mounted() {
    this.createNewSession();
    window.addEventListener("beforeunload", this.handleBeforeUnload);
  },
  methods: {
    handleBeforeUnload(event) {
      // Custom logic to handle when the user refreshes or leaves the page
      console.log("Page is about to be refreshed or closed", event);
      // Optional: Show a confirmation dialog (not supported by all browsers)
      event.preventDefault();
      // this.$store.dispatch('auth/logout');
      event.returnValue = ""; // Some browsers require returnValue to be set
    },
    async createNewSession() {
      const avatar = this.avatarID || "Tyler-insuit-20220721";

      const sessionInfo = await this.newSession("low", avatar, "");
      if (!sessionInfo) {
        return;
      }
      const { sdp: serverSdp, ice_servers2: iceServers } = sessionInfo;
      this.sessionInfo = sessionInfo;
      this.peerConnection = new RTCPeerConnection({ iceServers });
      this.peerConnection.ontrack = (event) => {
        if (event.track.kind === "audio" || event.track.kind === "video") {
          this.connected = true;
          this.$refs.mediaElement.srcObject = event?.streams?.[0];
        }
      };

      const remoteDescription = new RTCSessionDescription(serverSdp);
      await this.peerConnection.setRemoteDescription(remoteDescription);

      await this.startAndDisplaySession();
    },

    async startAndDisplaySession() {
      if (!this.sessionInfo) {
        return;
      }

      const localDescription = await this.peerConnection.createAnswer();
      await this.peerConnection.setLocalDescription(localDescription);

      this.peerConnection.onicecandidate = ({ candidate }) => {
        if (candidate) {
          this.handleICE(this.sessionInfo.session_id, candidate.toJSON());
        }
      };

      await this.startSession(this.sessionInfo.session_id, localDescription);
    },

    async repeatHandler() {
      if (!this.sessionInfo) {
        return;
      }

      const text = this.taskInput.trim();
      if (text === "") {
        return;
      }
      await this.repeat(this.sessionInfo.session_id, text);
    },

    async closeConnectionHandler() {
      if (!this.sessionInfo) {
        return;
      }

      this.peerConnection.close();
      await this.stopSession(this.sessionInfo.session_id);
    },
    async newSession(quality, avatar_id, voice_id) {
      try {
        const response = await this.$store.dispatch("heygen/newSession", {
          quality,
          avatar_id,
          voice_id,
        });
        return response;
      } catch (error) {
        alert(`New Session: ${error?.message || "An error occurred"}`);
        return null;
      }
    },
    async interruptTask() {
      const response = await this.$store.dispatch("heygen/interruptTask", {
        session_id: this.sessionInfo.session_id,
      });
      return response;
    },
    async handleICE(session_id, candidate) {
      const response = await this.$store.dispatch("heygen/handleICE", {
        session_id,
        candidate,
      });
      return response;
    },
    async startSession(session_id, sdp) {
      const response = await this.$store.dispatch("heygen/startSession", {
        session_id,
        sdp,
      });
      return response;
    },
    async repeat(session_id, text) {
      const response = await this.$store.dispatch("heygen/repeat", {
        session_id,
        text,
      });
      return response;
    },

    async stopSession(session_id) {
      const response = await this.$store.dispatch("heygen/stopSession", {
        session_id,
      });
      return response;
    },
  },
  watch: {
    taskInput() {
      console.log("taskInput changed");
      this.interruptTask();
      this.repeatHandler();
    },
  },
  async beforeUnmount() {
    await this.stopSession(this.sessionInfo.session_id);
  },
};
</script>

<style scoped>
video {
  width: 100%;
  height: 100%;
  border-radius: 50%; /* Circular border */
}

.lds-ripple,
.lds-ripple div {
  box-sizing: border-box;
}
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid currentColor;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 8px;
    height: 8px;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 8px;
    height: 8px;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 8px;
    height: 8px;
    opacity: 1;
  }
  100% {
    top: 0;
    left: 0;
    width: 80px;
    height: 80px;
    opacity: 0;
  }
}
</style>
