export const convertTime = (time) => {
  if (!time) {
    return "";
  }
  const currentDateTime = new Date(time);
  const formattedTime = `${currentDateTime
    .getHours()
    .toString()
    .padStart(2, "0")}:${currentDateTime
    .getMinutes()
    .toString()
    .padStart(2, "0")}`;
  const formattedDate = `${currentDateTime
    .getDate()
    .toString()
    .padStart(2, "0")}/${(currentDateTime.getMonth() + 1)
    .toString()
    .padStart(2, "0")}/${currentDateTime.getFullYear()}`;

  return `${formattedTime} ${formattedDate}`;
};
